import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Leaderboard from './components/Leaderboard';
import Home from './components/Home';
import Arcade from './components/Arcade';
import Meme from './components/Meme';
import HistoryPage from './components/History';

function App() {
  return (
    <Router>
      <div className="retro-bg">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/arcade" element={<Arcade />} />
          <Route path="/meme" element={<Meme />} />
          <Route path="/history" element={<HistoryPage />} />
        </Routes>
        <footer className="Footer">
          <h3>Connect with BTC on ETH</h3>
          <div className="Social-links">
            <div className="social-images">
              <a href="https://twitter.com/btc_on_eth" target="_blank" rel="noopener noreferrer">
                <img src="twitter.png" alt="Twitter" />
              </a>
              <a href="https://discord.gg/bp57JyBHkt" target="_blank" rel="noopener noreferrer">
                <img src="discord.png" alt="Discord" />
              </a>
              <a href="https://t.me/btc_on_eth" target="_blank" rel="noopener noreferrer">
                <img src="telegram.png" alt="Telegram" />
              </a>
              <a href="mailto: contact@btconeth.com" target="_blank" rel="noopener noreferrer">
                <img src="email.png" alt="Email" />
              </a>
              {/* Add more social media images as needed */}
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
