import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HistoryPage = () => {
  const [data, setData] = useState([]);
  const [sidebarContent, setSidebarContent] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetch('data.json')
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return response.text();  // Read as text first
  })
  .then(text => {
    console.log('Fetched text:', text);  // Log raw text
    return JSON.parse(text);  // Then parse the JSON
  })
  .then(json => {
    console.log(json)
    setData(json);
    generateGrid();
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
  }, []);

  const generateGrid = () => {
    const gridItems = [];
    const groups = [155, 366, 365, 365, 365, 366, 365, 365, 365, 366];
    let startDate = new Date('2015-07-30');
    let index = 0;
    const currentDate = new Date().toISOString().split('T')[0];
  
    groups.forEach((groupSize, groupIndex) => {
      const year = 2015 + groupIndex;
      year.toString().split('').forEach((yearDigit, digitIndex) => {
        gridItems.push(
          <div
            key={`year-${groupIndex}-${digitIndex}`}
            className="grid-item year-cell"
          >
            {yearDigit}
          </div>
        );
      });
  
      for (let i = 0; i < groupSize; i++, index++) {
        let cellDate = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
        let dateId = cellDate.toISOString().split('T')[0];
        let cellContent = '';
  
        const entry = data.find(item => item.date === dateId);
        if (entry && entry.Image) {
          cellContent = <img src={entry.Image} alt={entry['Project Name']} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />;
        }
  
        const backgroundColor = dateId === currentDate ? 'rgb(50, 150, 255)' : getShade(groupIndex);
  
        gridItems.push(
          <a
            key={dateId}
            href="#"
            className="grid-item"
            style={{ backgroundColor }}
            onClick={() => openSidebar(dateId)}
            title={dateId}
          >
            {cellContent}
          </a>
        );
      }
    });
  
    return gridItems;
  };
  

  const getShade = (groupIndex) => {
    const shades = [
      'rgb(255, 255, 255)', 'rgb(245, 245, 245)', 'rgb(235, 235, 235)', 
      'rgb(225, 225, 225)', 'rgb(215, 215, 215)', 'rgb(205, 205, 205)', 
      'rgb(195, 195, 195)', 'rgb(185, 185, 185)', 'rgb(175, 175, 175)', 
      'rgb(165, 165, 165)'
    ];
    return shades[groupIndex % shades.length];
  };

  const openSidebar = (dateId) => {
    const entries = data.filter(item => item.date === dateId);
    if (entries.length > 0) {
      const content = entries.map(entry => (
        <div className="project" key={entry['Project Name']}>
          <h2>{entry['Project Name']}</h2>
          {entry.Image && <img src={entry.Image} alt={entry['Project Name']} />}
          <p><strong>Date:</strong> {dateId}</p>
          <p><strong>Block:</strong> {entry.Block}</p>
          {entry.Website && <p><strong>Website:</strong> <a href={entry.Website} target="_blank" rel="noopener noreferrer">{entry.Website}</a></p>}
          <p><strong>Additional Info:</strong> {entry['Additional Info']}</p>
          <a href={`mailto:spiderwars758@proton.me?subject=Correction, Ethereum History&body=Correct the information that is wrong and submit:%0A%0A${encodeURIComponent(JSON.stringify(entry, null, 2))}`} className="correction-link">Is some info wrong?</a>
        </div>
      ));
      setSidebarContent(content);
    } else {
      const exampleEntry = {
        "date": dateId,
        "Block": "484730",
        "Project Name": "bitcoin on Ethereum",
        "Website": "https://www.btconeth.com/",
        "Additional Info": "A meme coin almost as old as Ethereum itself",
      };
      setSidebarContent(
        <>
          <p>No data available for date: {dateId}</p>
          <a href={`mailto:spiderwars758@proton.me?subject=Addition, Ethereum History&body=EXAMPLE, REPLACE WITH THE NEW PROJECT PROPOSAL:%0A%0A${encodeURIComponent(JSON.stringify(exampleEntry, null, 2))}`} className="add-project-link">Add project to date</a>
        </>
      );
    }
    setSidebarOpen(true);
  };

  const closeSidebar = () => setSidebarOpen(false);

  return (
    <div className='history-body'>
      <div className="textbox">
        <p>
          <br />
          This is a visual representation of the development and milestones of the Ethereum blockchain from its inception in 2015 to the present day in 2024. Each cell represents one day.
        </p>
        <p>Overview made by 'bitcoin on Ethereum' a bitcoin meme coin from November 3rd 2015</p>
      </div>

      <div className="grid-wrapper">
        <div className="grid-container" id="gridContainer">
          {generateGrid()}
        </div>
      </div>

      <div id="sidebar" className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={closeSidebar}>Close</button>
        <div className="sidebar-content" id="sidebar-content">
          {sidebarContent}
        </div>
      </div>

      <footer>
        <p>Comments: <a href="https://x.com/ethspiderwars" target="_blank" rel="noopener noreferrer">@ethspiderwars</a> -- <a href="https://discord.gg/bp57JyBHkt" target="_blank" rel="noopener noreferrer">Discord</a></p>
      </footer>
    </div>
  );
};

export default HistoryPage;
